import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { colors } from "../../../../../theme/astra/colors";
import { useDisplayPrice } from "../../../../../utils/useDisplayPrice";
import { useSelector } from "../../../../../store";
import { selectSelfSchoolCurrency } from "../../../../../slices/auth/selectors";
import { GetSchoolInquiriesForTeachersResponseDto } from "../../../../../slices/schoolInquiries/types";
import { useTranslation } from "react-i18next";
import { ItemsWithoutPriceTooltip } from "../../ItemsWithoutPriceTooltip";

type BudgetProps = {
  assignment: GetSchoolInquiriesForTeachersResponseDto["data"][number]["departmentInquiries"][number]["assignments"][number];
};

export const Budget = ({ assignment }: BudgetProps) => {
  const { t } = useTranslation("pages");

  const selfCurrency = useSelector(selectSelfSchoolCurrency);

  const displayPrice = useDisplayPrice();

  return (
    <ItemsWithoutPriceTooltip
      count={assignment.requestedItemsWithoutPrice}
      placement="bottom-start"
    >
      <Box
        sx={{
          flex: "1",
          display: "flex",
          gap: "8px",
          alignItems: "baseline",
          flexDirection: "column",
          marginLeft: "40px",
        }}
      >
        <Typography sx={{ fontSize: "14px", color: colors.dustBlue1 }}>
          {t("schoolInquiries.budgetInquiryTitle")},{" "}
          {selfCurrency?.code?.toUpperCase() ||
            t("currencies.qar", { ns: "common" })}
          :
        </Typography>

        <Box
          sx={{
            display: "flex",
            gap: "3px",
            alignItems: "baseline",
          }}
        >
          <BudgetPrice>
            {`${displayPrice(assignment.totalAmount) || 0}${
              assignment.requestedItemsWithoutPrice > 0 ? "+" : ""
            }`}
          </BudgetPrice>
          <BudgetCurrency>
            {selfCurrency?.code?.toUpperCase() ||
              t("currencies.qar", { ns: "common" })}
          </BudgetCurrency>
          <Typography
            sx={{
              fontSize: "16px",
              color: colors.dustBlue2,
              fontWeight: "500",
            }}
          >
            /
          </Typography>
          <BudgetPrice>{displayPrice(assignment.budget) || "N/A"}</BudgetPrice>
          <BudgetCurrency>
            {selfCurrency?.code?.toUpperCase() ||
              t("currencies.qar", { ns: "common" })}
          </BudgetCurrency>
        </Box>
      </Box>
    </ItemsWithoutPriceTooltip>
  );
};

const BudgetPrice = styled(Typography)({
  fontSize: "16px",
  color: colors.darkBlue,
  fontWeight: "500",
});

const BudgetCurrency = styled(Typography)({
  fontSize: "12px",
  color: colors.darkBlue,
  fontWeight: "500",
});
