import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useSelector } from "../../store";
import { selectSelf } from "../auth/selectors";
import {
  AssignmentSchoolStatus,
  DepartmentInquirySchoolStatus,
  InquirySchoolStatus,
  RequestedItemSchoolStatus,
} from "../../types";
import { SchoolTitles } from "./types";
import { ItemSchoolStatus } from "../items/types";

export const useSchoolTitles = () => {
  const { t } = useTranslation("common");

  return useCallback(
    (props: Partial<SchoolTitles>) => {
      return {
        principalTitle: props.principal ?? t("schoolTitles.principal"),
        headOfDepartmentTitle:
          props.headOfDepartment ?? t("schoolTitles.headOfDepartment"),
        teacherTitle: props.teacher ?? t("schoolTitles.teacher"),
      };
    },
    [t]
  );
};

export const useDisplayDepartmentInquirySchoolStatus = () => {
  const { t } = useTranslation("common");
  const getSchoolTitles = useSchoolTitles();

  return useCallback(
    (
      props: {
        schoolStatus: DepartmentInquirySchoolStatus;
      } & Pick<SchoolTitles, "principal" | "headOfDepartment">
    ) => {
      return t(
        `schoolDepartmentInquiryStatuses.${props.schoolStatus}`,
        getSchoolTitles(props)
      );
    },
    [getSchoolTitles, t]
  );
};

export const useDisplayAssignmentSchoolStatus = () => {
  const { t } = useTranslation("common");
  const self = useSelector(selectSelf);
  const getSchoolTitles = useSchoolTitles();

  return useCallback(
    (
      props: {
        schoolStatus: AssignmentSchoolStatus;
        ownAssignment: boolean;
      } & SchoolTitles
    ) => {
      if (!self) {
        return "";
      }

      const schoolTitles = getSchoolTitles(props);

      if (
        self.role === "headOfDepartment" &&
        props.ownAssignment &&
        props.schoolStatus === "readyForSending"
      ) {
        return t(`alternativeSchoolStatuses.readyForApproval`, schoolTitles);
      }

      return t(`assignmentSchoolStatuses.${props.schoolStatus}`, schoolTitles);
    },
    [getSchoolTitles, self, t]
  );
};

export const useDisplayItemSchoolStatus = () => {
  const { t } = useTranslation("common");
  const getSchoolTitles = useSchoolTitles();

  return useCallback(
    (
      props: {
        schoolStatus: ItemSchoolStatus;
      } & Pick<SchoolTitles, "principal" | "headOfDepartment">
    ) => {
      return t(
        `itemSchoolStatuses.${props.schoolStatus}`,
        getSchoolTitles(props)
      );
    },
    [getSchoolTitles, t]
  );
};

export const useDisplayRequestedItemSchoolStatus = () => {
  const { t } = useTranslation("common");
  const getSchoolTitles = useSchoolTitles();

  return useCallback(
    (
      props: {
        schoolStatus: RequestedItemSchoolStatus;
      } & SchoolTitles
    ) => {
      return t(
        `requestedItemSchoolStatuses.${props.schoolStatus}`,
        getSchoolTitles(props)
      );
    },
    [getSchoolTitles, t]
  );
};

/**
 * All action possibility checks need a rewrite, so they're only checked
 * on the server.
 */
export const usePreActionCheck = () => {
  return useCallback(
    (
      inquirySchoolStatus: InquirySchoolStatus,
      departmentInquirySchoolStatus?: DepartmentInquirySchoolStatus
    ) => {
      return (
        inquirySchoolStatus === "sentToAstra" ||
        inquirySchoolStatus === "cancelled" ||
        departmentInquirySchoolStatus === "cancelled"
      );
    },
    []
  );
};
