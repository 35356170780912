import React from "react";
import {
  AssignmentSchoolStatus,
  assignmentSchoolStatuses,
} from "../../../../../types";
import { StatusBar } from "../../StatusBar/StatusBar";
import { useTranslation } from "react-i18next";
import { ASSIGNMENT_STATUS_CHIP_STYLES } from "../../../../../consts";
import { useDisplayAssignmentSchoolStatus } from "../../../../../slices/schoolInquiries/hooks";
import { SchoolTitles } from "../../../../../slices/schoolInquiries/types";

type StatusesProps = {
  current: AssignmentSchoolStatus;
  assignmentLink: string;
  schoolTitles: SchoolTitles;
};

export const Statuses = ({
  current,
  assignmentLink,
  schoolTitles,
}: StatusesProps) => {
  const { t } = useTranslation("pages");

  const displayAssignmentSchoolStatus = useDisplayAssignmentSchoolStatus();

  const statuses = assignmentSchoolStatuses
    .filter((status) => {
      if (status === "needReviewByTeacher") {
        return status === current;
      }

      return true;
    })
    .map((status) => ({
      status,
      label: displayAssignmentSchoolStatus({
        schoolStatus: status,
        ownAssignment: false,
        ...schoolTitles,
      }),
      link:
        status === "needReviewByTeacher" && current === status
          ? {
              to: assignmentLink,
              label: t("schoolInquiries.reviewNow"),
            }
          : null,
      colors: ASSIGNMENT_STATUS_CHIP_STYLES[status],
    }));

  return <StatusBar statuses={statuses} current={current} />;
};
