import React from "react";
import { ListColumn, RowBase } from "../../types";
import { Cell } from "../Cell";

type SubRowProps<
  Row extends RowBase,
  SubRow extends RowBase = never,
  SubRowsKey extends keyof Row | undefined = undefined
> = {
  columns: ListColumn<Row, SubRow, SubRowsKey>[];
  row: Row;
  subRow: SubRow;
  displayCheckboxCell?: boolean;
};

export const SubRow = <
  Row extends RowBase,
  SubRow extends RowBase = never,
  SubRowsKey extends keyof Row | undefined = undefined
>({
  columns,
  row,
  subRow,
  displayCheckboxCell,
}: SubRowProps<Row, SubRow, SubRowsKey>) => {
  if (!subRow) {
    return null;
  }
  return (
    <>
      <Cell filler />
      {displayCheckboxCell && <Cell filler />}
      {columns.map((column) => {
        // @ts-ignore
        const cell = subRow[column.field];
        const key = `subrow-${subRow.id}-${column.field}`;

        if (column.mirrorMasterCell) {
          return (
            <Cell key={key}>
              {column.renderCell
                ? column.renderCell(row, {
                    expanded: true,
                  })
                : cell}
            </Cell>
          );
        }

        if (column.skipOnSubRow) {
          return <Cell key={key} filler />;
        }

        // I honestly forgot if this can be removed, but it fixes the problem
        // now, so I'm leaving this for future generations or even future me
        // if (!cell) {
        //   return <Cell key={key} />;
        // }

        return (
          <Cell key={key} sx={column.boxSx}>
            {column.renderSubRowCell
              ? // @ts-ignore
                column.renderSubRowCell(subRow, row)
              : cell}
          </Cell>
        );
      })}
    </>
  );
};
