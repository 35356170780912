import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, styled } from "@mui/material";
import { ConfirmationButton } from "../../../../../../components/ConfirmationButton";
import { useSendSchoolDepartmentInquiryToPrincipalMutation } from "../../../../../../slices/schoolInquiries/api";
import { useMessageHandlers } from "../../../../../../utils/useMessageHandlers";
import { Hint } from "../../../../../../components/hints/Hint";
import { colors } from "../../../../../../theme/astra/colors";
import { useSelector } from "../../../../../../store";
import { selectSelf } from "../../../../../../slices/auth/selectors";

type SendToPrincipalProps = {
  inquiryId: number;
  departmentInquiryId: number;
  disabled: boolean;
  displayHelp: boolean;
  principalTitle: string | null;
};

export const SendToPrincipal = ({
  inquiryId,
  departmentInquiryId,
  disabled,
  displayHelp,
  principalTitle,
}: SendToPrincipalProps) => {
  const { t } = useTranslation("pages");
  const { handleSuccess, handleError } = useMessageHandlers();

  const self = useSelector(selectSelf);

  const [sendDepartmentInquiry, { isLoading: isSending }] =
    useSendSchoolDepartmentInquiryToPrincipalMutation();

  const sendToPrincipalHandler = useCallback(() => {
    if (disabled || displayHelp) {
      return;
    }

    sendDepartmentInquiry({
      inquiryId,
      departmentInquiryId,
    })
      .unwrap()
      .then(handleSuccess)
      .catch(handleError);
  }, [
    departmentInquiryId,
    disabled,
    handleError,
    handleSuccess,
    displayHelp,
    inquiryId,
    sendDepartmentInquiry,
  ]);

  if (self?.role !== "headOfDepartment") {
    return null;
  }

  const principalTitleLine =
    principalTitle ?? t("schoolTitles.principal", { ns: "common" });

  return (
    <Hint
      helpText={
        displayHelp
          ? t("hints.schoolInquiryDetails.sendTo.disabled")
          : t("hints.schoolInquiryDetails.sendTo.available")
      }
      // hide={
      //   !departmentInquiry ||
      //   departmentInquiry.schoolStatus === "approvedByHead" ||
      //   departmentInquiry.schoolStatus === "cancelled" ||
      //   departmentInquiry.schoolStatus === "approvedByPrincipal"
      // }
    >
      <ConfirmationButton
        component={SendToAstraButton}
        variant="contained"
        onClick={displayHelp ? undefined : sendToPrincipalHandler}
        confirmationTitle={
          displayHelp
            ? t(
                "schoolInquiryDetails.departmentControls.sendToPrincipal.approveAllFirst"
              )
            : t(
                "schoolInquiryDetails.departmentControls.sendToPrincipal.confirmationTitle",
                {
                  principalTitle: principalTitleLine?.toLowerCase(),
                }
              )
        }
        confirmationDescription={
          displayHelp
            ? t(
                "schoolInquiryDetails.departmentControls.sendToPrincipal.checkListsFirst",
                {
                  principalTitle: principalTitleLine,
                }
              )
            : t("noUndo", { ns: "common" })
        }
        loading={isSending}
        disabled={disabled && !displayHelp}
        proceedButtonText={displayHelp ? "Ok" : undefined}
        sx={{
          textTransform: "capitalize",
        }}
      >
        {t("schoolInquiryDetails.departmentControls.sendToPrincipal.label", {
          principalTitle: principalTitleLine,
        })}
      </ConfirmationButton>
    </Hint>
  );
};

const SendToAstraButton = styled(Button)({
  color: colors.white,
  backgroundColor: colors.blue,
});
