import { useCallback, useState } from "react";

export const useListTools = () => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const selectedIdsChangeHandler = useCallback(
    (newSelectionModel: number[]) => {
      setSelectedIds(newSelectionModel);
    },
    []
  );

  const [expandedIds, setExpandedIds] = useState<number[]>([]);

  const expandedIdsChangeHandler = useCallback((expandedIds: number[]) => {
    setExpandedIds(expandedIds);
  }, []);

  return {
    selectedIds,
    selectedIdsChangeHandler,
    expandedIds,
    expandedIdsChangeHandler,
  };
};
