import React, { useCallback } from "react";
import { Button, styled } from "@mui/material";
import { ConfirmationButton } from "../../../../../../components/ConfirmationButton";
import { useTranslation } from "react-i18next";
import { useMessageHandlers } from "../../../../../../utils/useMessageHandlers";
import { useSendAssignmentToHeadMutation } from "../../../../../../slices/schoolInquiries/api";
import { colors } from "../../../../../../theme/astra/colors";
import { useSchoolTitles } from "../../../../../../slices/schoolInquiries/hooks";

type SendToHeadProps = {
  inquiryId: number;
  departmentInquiryId: number;
  assignmentId: number;
  disabled: boolean;
  displayHelp: boolean;
  headOfDepartmentTitle: string | null;
};

export const SendToHead = ({
  inquiryId,
  departmentInquiryId,
  assignmentId,
  disabled,
  displayHelp,
  headOfDepartmentTitle,
}: SendToHeadProps) => {
  const { t } = useTranslation("pages");
  const { handleSuccess, handleError } = useMessageHandlers();
  const getSchoolTitles = useSchoolTitles();

  const [sendAssignmentToHead, { isLoading: isSending }] =
    useSendAssignmentToHeadMutation();

  const sendToHeadClickHandler = useCallback(() => {
    if (disabled || displayHelp) {
      return;
    }

    sendAssignmentToHead({
      inquiryId,
      departmentInquiryId,
      assignmentId,
    })
      .unwrap()
      .then(handleSuccess)
      .catch(handleError);
  }, [
    assignmentId,
    departmentInquiryId,
    disabled,
    handleError,
    handleSuccess,
    displayHelp,
    inquiryId,
    sendAssignmentToHead,
  ]);

  const schoolTitles = getSchoolTitles({
    headOfDepartment: headOfDepartmentTitle,
  });

  return (
    <ConfirmationButton
      component={SendToAstraButton}
      onClick={displayHelp ? undefined : sendToHeadClickHandler}
      proceedButtonText={displayHelp ? "Ok" : undefined}
      variant="contained"
      disabled={disabled && !displayHelp}
      confirmationTitle={
        displayHelp
          ? t(
              "schoolInquiryDetails.assignmentControls.sendToHead.approveAllFirst"
            )
          : t(
              "schoolInquiryDetails.assignmentControls.sendToHead.confirmationTitle",
              schoolTitles
            )
      }
      confirmationDescription={
        displayHelp
          ? t(
              "schoolInquiryDetails.assignmentControls.sendToHead.approveItemsFirst"
            )
          : t("noUndo", { ns: "common" })
      }
      loading={isSending}
      sx={{
        textTransform: "capitalize",
      }}
    >
      {t(
        "schoolInquiryDetails.assignmentControls.sendToHead.label",
        schoolTitles
      )}
    </ConfirmationButton>
  );
};

const SendToAstraButton = styled(Button)({
  color: colors.white,
  backgroundColor: colors.blue,
});
