import React from "react";
import { AssignmentSchoolStatus } from "../../../../../types";
import { ASSIGNMENT_STATUS_CHIP_STYLES } from "../../../../../consts";
import { SchoolStatusChip } from "../../../../../components/SchoolStatusChip";
import { SchoolTitles } from "../../../../../slices/schoolInquiries/types";
import { useDisplayAssignmentSchoolStatus } from "../../../../../slices/schoolInquiries/hooks";

type StatusProps = {
  schoolStatus: AssignmentSchoolStatus;
  schoolTitles: SchoolTitles;
};

export const Status = ({ schoolStatus, schoolTitles }: StatusProps) => {
  const displayAssignmentSchoolStatus = useDisplayAssignmentSchoolStatus();

  return (
    <SchoolStatusChip
      chipStyle={ASSIGNMENT_STATUS_CHIP_STYLES[schoolStatus]}
      label={displayAssignmentSchoolStatus({
        schoolStatus,
        ownAssignment: false, // not a logical property name, because it implies heads of departments
        ...schoolTitles,
      })}
    />
  );
};
