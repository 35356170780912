import {
  useSchoolInquiryItemsListColumns,
  UseSchoolInquiryItemsListColumnsProps,
} from "../useSchoolInquiryItemsListColumns";
import { ItemSchoolStatus } from "../../../../../slices/items/types";
import {
  GetSchoolDepartmentInquiryItemsResponseDto,
  ItemActions,
  SchoolTitles,
} from "../../../../../slices/schoolInquiries/types";
import React, { useMemo } from "react";
import { RequestedItemQuantityField } from "../fields/RequestedItemQuantityField";
import { SchoolStatusChip } from "../../../../../components/SchoolStatusChip";
import {
  ITEM_SCHOOL_STATUS_CHIP_STYLES,
  REQUEST_ITEM_SCHOOL_STATUS_CHIP_STYLES,
} from "../../../../../consts";
import { ItemStatusActions } from "../actions/items/ItemStatusActions";
import { RequestedItemStatusActions } from "../actions/requestedItems/RequestedItemStatusActions";
import { useTranslation } from "react-i18next";
import { useDisplayTotalAmount } from "../useDisplayTotalAmount";
import { useSelector } from "../../../../../store";
import { selectSelf } from "../../../../../slices/auth/selectors";
import { Box } from "@mui/material";
import { ItemReviewComments } from "../reviewComments/ItemReviewComments";
import { RequestedItemReviewComments } from "../reviewComments/RequestedItemReviewComments";
import {
  useDisplayItemSchoolStatus,
  useDisplayRequestedItemSchoolStatus,
} from "../../../../../slices/schoolInquiries/hooks";

type LocalColumnsProp = {
  schoolStatus: ItemSchoolStatus;
  reviewCommentsCount: number;
  possibleActions: ItemActions[];
  schoolTitles: Pick<SchoolTitles, "principal" | "headOfDepartment">;
  requestedItems: GetSchoolDepartmentInquiryItemsResponseDto[number]["requestedItems"];
};

type SubRowKeyProp = "requestedItems";

type SubRowColumnsProp =
  GetSchoolDepartmentInquiryItemsResponseDto[number][SubRowKeyProp][number];

type Columns = UseSchoolInquiryItemsListColumnsProps<
  LocalColumnsProp,
  SubRowColumnsProp,
  SubRowKeyProp
>["columns"];

export const useDepartmentInquiryItemsColumns = ({
  baseUrl,
  inquiryId,
  departmentInquiryId,
  loading,
}: {
  baseUrl: string;
  inquiryId: number;
  departmentInquiryId?: number;
  loading: boolean;
}) => {
  const { t } = useTranslation("pages");

  const self = useSelector(selectSelf);

  const displayItemSchoolStatus = useDisplayItemSchoolStatus();
  const displayRequestedItemSchoolStatus =
    useDisplayRequestedItemSchoolStatus();
  const displayTotalAmount = useDisplayTotalAmount();

  const columns = useMemo(() => {
    const commonColumns: Columns = [
      {
        order: 4,
        column: {
          field: "quantity",
          width: "150px",
          name: t("schoolInquiryDetails.departmentColumns.quantity"),
          renderCell: (row) => row.quantity,
          renderSubRowCell: (requestedItem) => {
            if (!departmentInquiryId) {
              return null;
            }

            if (requestedItem.possibleActions.includes("hideChangeQuantity")) {
              return requestedItem.quantity;
            }

            return (
              <RequestedItemQuantityField
                id={requestedItem.id}
                value={requestedItem.quantity}
                inquiryId={inquiryId}
                departmentInquiryId={departmentInquiryId}
                assignmentId={requestedItem.assignmentId}
                loading={loading}
                disabled={
                  !requestedItem.possibleActions.includes("changeQuantity")
                }
              />
            );
          },
        },
      },
      {
        order: 9,
        column: {
          field: "totalAmount",
          name: t("schoolInquiryDetails.departmentColumns.totalAmount"),
          renderCell: ({ productCode, retailPrice, currency, totalAmount }) =>
            displayTotalAmount({
              productCode,
              retailPrice,
              currency,
              totalAmount,
            }),
          renderSubRowCell: (requestedItem, item) =>
            displayTotalAmount({
              productCode: item.productCode,
              retailPrice: item.retailPrice,
              currency: item.currency,
              totalAmount: requestedItem.totalAmount,
            }),
        },
      },
      {
        order: 10,
        column: {
          field: "schoolStatus",
          name: t("schoolInquiryDetails.departmentColumns.status"),
          width: "250px",
          renderCell: (item) =>
            departmentInquiryId && (
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <SchoolStatusChip
                  label={displayItemSchoolStatus({
                    schoolStatus: item.schoolStatus,
                    ...item.schoolTitles,
                  })}
                  chipStyle={ITEM_SCHOOL_STATUS_CHIP_STYLES[item.schoolStatus]}
                  detached
                />
                <ItemReviewComments
                  inquiryId={inquiryId}
                  departmentInquiryId={departmentInquiryId}
                  id={item.id}
                  reviewCommentsCount={item.reviewCommentsCount}
                  code={item.productCode}
                />
              </Box>
            ),
          renderSubRowCell: (requestedItem, item) =>
            departmentInquiryId && (
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <SchoolStatusChip
                  label={displayRequestedItemSchoolStatus({
                    schoolStatus: requestedItem.schoolStatus,
                    ...requestedItem.schoolTitles,
                  })}
                  chipStyle={
                    REQUEST_ITEM_SCHOOL_STATUS_CHIP_STYLES[
                      requestedItem.schoolStatus
                    ]
                  }
                />
                <RequestedItemReviewComments
                  inquiryId={inquiryId}
                  departmentInquiryId={departmentInquiryId}
                  assignmentId={requestedItem.assignmentId}
                  id={requestedItem.id}
                  reviewCommentsCount={requestedItem.reviewCommentsCount}
                  code={item.productCode}
                />
              </Box>
            ),
        },
      },
      {
        order: 11,
        column: {
          field: "actions",
          name: t("columns.actions", { ns: "common" }),
          boxSx: {
            justifyContent: "flex-end",
          },
          renderCell: (item) =>
            departmentInquiryId && (
              <ItemStatusActions
                inquiryId={inquiryId}
                departmentInquiryId={departmentInquiryId}
                assignmentsIds={item.requestedItems.map(
                  (requestedItem) => requestedItem.assignmentId
                )}
                itemId={item.id}
                possibleActions={item.possibleActions}
                code={item.productCode}
              />
            ),
          renderSubRowCell: (requestedItem, item) =>
            departmentInquiryId && (
              <RequestedItemStatusActions
                inquiryId={inquiryId}
                departmentInquiryId={departmentInquiryId}
                assignmentId={requestedItem.assignmentId}
                requestedItemId={requestedItem.id}
                possibleActions={requestedItem.possibleActions}
                code={item.productCode}
              />
            ),
        },
      },
    ];

    if (self?.role === "headOfDepartment") {
      commonColumns.unshift({
        order: 4,
        column: {
          field: "sourceList",
          width: "150px",
          name: t("schoolInquiryDetails.departmentColumns.sourceList"),
          renderCell: (row, options) =>
            !options.expanded ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                {row.requestedItems.map((requestedItem) => (
                  <Box key={requestedItem.id} component="span">
                    {requestedItem.sourceList}
                  </Box>
                ))}
              </Box>
            ) : null,
        },
      });
    }

    return commonColumns;
  }, [
    departmentInquiryId,
    displayItemSchoolStatus,
    displayRequestedItemSchoolStatus,
    displayTotalAmount,
    inquiryId,
    loading,
    self?.role,
    t,
  ]);

  return useSchoolInquiryItemsListColumns<
    LocalColumnsProp,
    SubRowColumnsProp,
    SubRowKeyProp
  >({
    baseUrl,
    columns,
  });
};
