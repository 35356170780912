import React, { useCallback, useMemo } from "react";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Grid,
  Link as MaterialLink,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useSendAssignmentToHeadMutation } from "../../../../../slices/schoolInquiries/api";
import { useMessageHandlers } from "../../../../../utils/useMessageHandlers";
import { ASSIGNMENT_STATUS_CHIP_STYLES } from "../../../../../consts";
import { Add } from "@mui/icons-material";
import { colors } from "../../../../../theme/astra/colors";
import { useSelector } from "../../../../../store";
import {
  selectSelfId,
  selectSelfRole,
} from "../../../../../slices/auth/selectors";
import photoPlaceholder from "../../../../../assets/no-photo.svg";
import { ImageSliderFixedHeight } from "../../../../../components/ImageSliderFixedHeight";
import { ReactComponent as ShopCartIcon } from "../../../../../assets/newDesignItems/shopping-cart.svg";
import { ConfirmationButton } from "../../../../../components/ConfirmationButton";
import {
  useAdminSchoolInquiryTools,
  useSchoolInquiriesTableUrlTools,
} from "../../../hooks";
import { InquirySchoolStatus, RouteState } from "../../../../../types";
import { GetSchoolInquiriesForTeachersResponseDto } from "../../../../../slices/schoolInquiries/types";
import { SchoolStatusChip } from "../../../../../components/SchoolStatusChip";
import { Statuses } from "./Statuses";
import {
  useDisplayAssignmentSchoolStatus,
  usePreActionCheck,
  useSchoolTitles,
} from "../../../../../slices/schoolInquiries/hooks";
import { Budget } from "./Budget";

type DepartmentInquiryProps = {
  inquiryId: number;
  inquirySchoolStatus: InquirySchoolStatus;
  departmentInquiry: GetSchoolInquiriesForTeachersResponseDto["data"][number]["departmentInquiries"][number];
};

export const DepartmentInquiry = ({
  inquiryId,
  inquirySchoolStatus,
  departmentInquiry: { id, assignments, department, user, schoolStatus },
}: DepartmentInquiryProps) => {
  const { t } = useTranslation("pages");
  const { handleSuccess, handleError } = useMessageHandlers();
  const history = useHistory<RouteState>();
  const selfId = useSelector(selectSelfId);
  const selfRole = useSelector(selectSelfRole);
  const { baseUrl } = useSchoolInquiriesTableUrlTools();
  const adminTool = useAdminSchoolInquiryTools();

  const currentRole = adminTool?.userRole || selfRole;
  const currentId = adminTool?.userId || selfId;
  const displayAssignmentSchoolStatus = useDisplayAssignmentSchoolStatus();
  const getSchoolTitles = useSchoolTitles();

  const selfAssignment = assignments.find(
    (assignment) => assignment.user.id === currentId
  );

  const addItemHandler = useCallback(() => {
    if (selfAssignment) {
      history.push(
        `${baseUrl}/${inquiryId}/department/${id}/assignment/${selfAssignment.id}/addItem`,
        { justGoBack: true }
      );
    }
  }, [baseUrl, history, id, inquiryId, selfAssignment]);

  const goToListHandler = useCallback(() => {
    if (selfAssignment) {
      history.push(
        `${baseUrl}/${inquiryId}/details/${id}/assignments/${selfAssignment.id}`,
        {
          justGoBack: true,
          scrollToTop: true,
        }
      );
    }
  }, [baseUrl, history, id, inquiryId, selfAssignment]);

  const [sendAssignmentToHead, { isLoading: isSending }] =
    useSendAssignmentToHeadMutation();

  const sendToHeadClickHandler = useCallback(() => {
    if (id && selfAssignment?.id && inquiryId) {
      sendAssignmentToHead({
        inquiryId,
        departmentInquiryId: id,
        assignmentId: selfAssignment.id,
      })
        .unwrap()
        .then(handleSuccess)
        .catch(handleError);
    }
  }, [
    handleError,
    handleSuccess,
    id,
    inquiryId,
    selfAssignment?.id,
    sendAssignmentToHead,
  ]);

  const preActionCheck = usePreActionCheck();

  const isSendingToHeadDisabled = useMemo(() => {
    if (!selfAssignment) {
      return true;
    }

    if (preActionCheck(inquirySchoolStatus, schoolStatus)) {
      return true;
    }

    return (
      selfAssignment.schoolStatus !== "readyForSending" &&
      !(
        selfAssignment.schoolStatus === "needReviewByTeacher" &&
        selfAssignment.requestedItems.every(
          ({ schoolStatus }) =>
            schoolStatus === "approvedByTeacher" ||
            schoolStatus === "approvedByHead" ||
            schoolStatus === "approvedByPrincipal" ||
            schoolStatus === "cancelled"
        )
      )
    );
  }, [inquirySchoolStatus, preActionCheck, schoolStatus, selfAssignment]);

  const showHelpForSendingToHead = useMemo(() => {
    if (!selfAssignment) {
      return true;
    }

    if (preActionCheck(inquirySchoolStatus, schoolStatus)) {
      return false;
    }

    return (
      selfAssignment.schoolStatus === "new" ||
      selfAssignment.schoolStatus === "inProgress" ||
      (selfAssignment.schoolStatus === "needReviewByTeacher" &&
        selfAssignment.requestedItems.some(
          ({ schoolStatus }) =>
            schoolStatus !== "approvedByTeacher" &&
            schoolStatus !== "approvedByHead" &&
            schoolStatus !== "cancelled"
        ))
    );
  }, [inquirySchoolStatus, preActionCheck, schoolStatus, selfAssignment]);

  const goToEShopDisabled = useMemo(() => {
    if (preActionCheck(inquirySchoolStatus)) {
      return true;
    }

    return (
      (currentRole !== "headOfDepartment" && currentRole !== "teacher") ||
      currentRole !== selfAssignment?.user.role ||
      (selfAssignment?.schoolStatus !== "new" &&
        selfAssignment?.schoolStatus !== "inProgress" &&
        selfAssignment?.schoolStatus !== "readyForSending" &&
        selfAssignment?.schoolStatus !== "needReviewByTeacher") ||
      schoolStatus === "cancelled"
    );
  }, [
    preActionCheck,
    inquirySchoolStatus,
    currentRole,
    selfAssignment?.user.role,
    selfAssignment?.schoolStatus,
    schoolStatus,
  ]);

  if (
    !selfAssignment ||
    currentRole !== "teacher" ||
    selfAssignment.user.id !== currentId
  ) {
    return null;
  }

  if (!selfAssignment) {
    return null;
  }

  const assignmentLink = `/schoolinquiries/${inquiryId}/details/${id}/assignments/${selfAssignment.id}`;
  const schoolTitles = getSchoolTitles(selfAssignment.schoolTitles);

  return (
    <Row>
      <Card
        variant="outlined"
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          border,
          backgroundColor: colors.white,
        }}
      >
        <CardMedia
          sx={{
            padding: 2,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            borderBottom: border,
            paddingInline: "32px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 1,
            }}
          >
            <Box sx={{ display: "flex", flex: "1" }}>
              <Box sx={{ marginRight: "10px" }}>
                <ShopCartIcon />
              </Box>
              <Box sx={{ marginRight: "40px" }}>
                <Title>{department.name}</Title>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography
                    sx={{
                      color: colors.dustBlue1,
                      fontSize: "13px",
                      marginRight: "5px",
                    }}
                  >
                    {`${user?.firstName} ${user?.lastName}`}
                  </Typography>
                  <Typography
                    sx={{ color: colors.dustBlue1, fontSize: "10px" }}
                  >
                    {`• ${
                      user?.title ?? t(`roles.${user?.role}`, { ns: "common" })
                    }`}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <SchoolStatusChip
                  chipStyle={
                    ASSIGNMENT_STATUS_CHIP_STYLES[selfAssignment?.schoolStatus]
                  }
                  label={displayAssignmentSchoolStatus({
                    schoolStatus: selfAssignment.schoolStatus,
                    ownAssignment: selfAssignment.user.id === selfId,
                    ...selfAssignment.schoolTitles,
                  })}
                />
              </Box>
            </Box>
            <Budget assignment={selfAssignment} />
            <Box
              sx={{
                flex: "1",
                display: "flex",
                gap: "20px",
                justifyContent: "end",
              }}
            >
              <ConfirmationButton
                component={SendToAstraButton}
                variant="contained"
                onClick={
                  showHelpForSendingToHead
                    ? goToListHandler
                    : sendToHeadClickHandler
                }
                confirmationTitle={
                  showHelpForSendingToHead
                    ? t(
                        "schoolInquiryDetails.assignmentControls.sendToHead.approveAllFirst"
                      )
                    : t(
                        "schoolInquiryDetails.assignmentControls.sendToHead.confirmationTitle",
                        schoolTitles
                      )
                }
                confirmationDescription={
                  showHelpForSendingToHead
                    ? t(
                        "schoolInquiryDetails.assignmentControls.sendToHead.sendToHeadOnlyAfterApproving",
                        schoolTitles
                      )
                    : t("noUndo", { ns: "common" })
                }
                proceedButtonText={
                  showHelpForSendingToHead
                    ? t(
                        "schoolInquiryDetails.assignmentControls.sendToHead.goToListLabel"
                      )
                    : undefined
                }
                loading={isSending}
                disabled={isSendingToHeadDisabled && !showHelpForSendingToHead}
              >
                {t(
                  "schoolInquiryDetails.assignmentControls.sendToHead.label",
                  schoolTitles
                )}
              </ConfirmationButton>
            </Box>
          </Box>
          <Statuses
            current={selfAssignment.schoolStatus}
            assignmentLink={assignmentLink}
            schoolTitles={selfAssignment.schoolTitles}
          />
        </CardMedia>
        <Stack
          sx={{
            marginTop: "10px",
            paddingInline: "12px",
            paddingBlock: "20px",
            marginBottom: "40px",
          }}
        >
          <Row
            sx={{
              gap: "32px",
              justifyContent: "start",
              alignItems: "center",
              overflow: "scroll",
            }}
          >
            {!goToEShopDisabled && selfRole !== "admin" && (
              <Box
                onClick={addItemHandler}
                sx={{
                  cursor: "pointer",
                  minWidth: "128px",
                  width: "128px",
                  height: "128px",
                  border: "1px solid #C5D2E0",
                  borderRadius: "4px",
                  display: "flex",
                  "&:hover": {
                    backgroundColor: colors.whitishGray,
                  },
                }}
              >
                <Row sx={{ alignItems: "center" }}>
                  <Add sx={{ lineHeight: "24px", color: "#5E7DA0" }} />
                  <Typography
                    sx={{
                      fontSize: "13px",
                      color: "#5E7DA0",
                    }}
                  >
                    {t("schoolInquiryDetails.addItem")}
                  </Typography>
                </Row>
              </Box>
            )}
            {selfAssignment.requestedItems.map((requestedItem) => (
              <Tooltip
                key={`teacher-item-list-${requestedItem.id}`}
                title={`${requestedItem.supplierProductCode?.code || ""} ${
                  requestedItem.name || ""
                }`.trim()}
              >
                <Box
                  sx={{
                    minWidth: "128px",
                    width: "128px",
                    height: "128px",
                    border: "1px solid #C5D2E0",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {requestedItem?.images && requestedItem?.images.length > 0 ? (
                    <ImageSliderFixedHeight
                      images={[
                        {
                          src: requestedItem.images[0],
                          alt: requestedItem.name ?? t("products.imgAlt"),
                        },
                      ]}
                      height={128}
                      sx={{
                        width: "100%",
                        "& img": {
                          borderRadius: "4px",
                        },
                      }}
                    />
                  ) : (
                    <img
                      src={photoPlaceholder}
                      style={{ padding: 16, width: "128px" }}
                    />
                  )}
                </Box>
              </Tooltip>
            ))}
          </Row>
          <MaterialLink
            component={Link}
            to={{
              pathname: assignmentLink,
              state: {
                justGoBack: true,
                scrollToTop: true,
              },
            }}
            sx={{
              fontSize: "13px",
              fontWeight: 500,
              position: "absolute",
              bottom: "20px",
              right: "40px",
            }}
          >
            {t("schoolInquiryDetails.viewAllItems")}
          </MaterialLink>
        </Stack>
      </Card>
    </Row>
  );
};

const SendToAstraButton = styled(Button)({
  color: colors.white,
  backgroundColor: colors.blue,
});

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "22px",
  fontWeight: "500",
  color: theme.palette.primary.dark,
  lineHeight: "28px",
}));

const Row = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "baseline",
  padding: "10px 16px",
});

const border = "1px solid #CFDFEF";
