import React, { useMemo } from "react";
import { StatusBar } from "../../StatusBar/StatusBar";
import {
  DepartmentInquirySchoolStatus,
  departmentInquirySchoolStatuses,
} from "../../../../../types";
import { useTranslation } from "react-i18next";
import { DEPARTMENT_INQUIRY_STATUS_CHIP_STYLES } from "../../../../../consts";
import {
  GetSchoolInquiriesForHeadsResponseDto,
  SchoolTitles,
} from "../../../../../slices/schoolInquiries/types";
import { useDisplayDepartmentInquirySchoolStatus } from "../../../../../slices/schoolInquiries/hooks";

const statusesWithLink: DepartmentInquirySchoolStatus[] = [
  "pendingApproval",
  "needReviewByHead",
];

type StatusesProps = {
  current: DepartmentInquirySchoolStatus;
  inquiryId: number;
  departmentInquiryId: number;
  schoolTitles: Pick<SchoolTitles, "principal" | "headOfDepartment">;
  assignments: GetSchoolInquiriesForHeadsResponseDto["data"][number]["departmentInquiries"][number]["assignments"];
};

export const Statuses = ({
  current,
  inquiryId,
  departmentInquiryId,
  schoolTitles,
  assignments,
}: StatusesProps) => {
  const { t } = useTranslation("pages");

  const displayDepartmentInquirySchoolStatus =
    useDisplayDepartmentInquirySchoolStatus();

  const link = useMemo(() => {
    if (!statusesWithLink.includes(current)) {
      return null;
    }

    const assignment = assignments.find((assignment) => {
      if (
        assignment.schoolStatus === "readyForSending" &&
        assignment.user.role === "headOfDepartment"
      ) {
        return true;
      }

      return assignment.schoolStatus === "approvedByTeacher";
    });

    if (assignment) {
      return `/schoolinquiries/${inquiryId}/details/${departmentInquiryId}/assignments/${assignment.id}`;
    }

    return `/schoolinquiries/${inquiryId}/details/${departmentInquiryId}/items`;
  }, [assignments, current, departmentInquiryId, inquiryId]);

  const statuses = departmentInquirySchoolStatuses
    .filter((status) => {
      if (status === "needReviewByHead" || status === "pendingApproval") {
        return status === current;
      }

      return true;
    })
    .map((status) => {
      const displayLink = link && current === status;

      return {
        status,
        colors: DEPARTMENT_INQUIRY_STATUS_CHIP_STYLES[status],
        label: displayDepartmentInquirySchoolStatus({
          schoolStatus: status,
          ...schoolTitles,
        }),
        link: displayLink
          ? {
              to: link,
              label: t("schoolInquiries.reviewNow"),
            }
          : null,
      };
    });

  return <StatusBar statuses={statuses} current={current} />;
};
