import React from "react";
import { alpha, Box, Tooltip, TooltipProps, Typography } from "@mui/material";
import { colors } from "../../../theme/astra/colors";
import { useTranslation } from "react-i18next";

type ItemsWithoutPriceTooltipProps = Pick<
  TooltipProps,
  "placement" | "children"
> & {
  count: number;
};

export const ItemsWithoutPriceTooltip = ({
  children,
  count,
  ...props
}: ItemsWithoutPriceTooltipProps) => {
  const { t } = useTranslation("pages");

  if (count === 0) {
    return children;
  }

  return (
    <Tooltip
      slotProps={{
        popper: {
          sx: {
            ".MuiTooltip-tooltip": {
              backgroundColor: "white !important",
              border: `1px solid ${colors.dustBlue4}`,
              boxShadow: `0 7px 30px ${alpha(colors.darkBlue, 0.15)}`,
            },
          },
        },
      }}
      title={
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: "4px",
            padding: "3px 8px 4px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              component="span"
              sx={{
                color: colors.darkBlue,
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: "500",
              }}
            >
              +{count}
            </Typography>
            <Typography
              component="span"
              sx={{
                color: colors.dustBlue1,
                fontSize: "10px",
                lineHeight: "15px",
                fontWeight: "400",
              }}
            >
              {t("schoolInquiries.itemsWithoutPrice", {
                count,
              })}
            </Typography>
          </Box>
        </Box>
      }
      {...props}
    >
      {children}
    </Tooltip>
  );
};
