import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import legacyEn from "./locales/en/legacy";
import commonEn from "./locales/en/common";
import pagesEn from "./locales/en/pages";
import componentsEn from "./locales/en/components";
import validationErrorsEn from "./locales/en/validationErrors";
import layoutsEn from "./locales/en/layouts";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      legacy: legacyEn,
      common: commonEn,
      pages: pagesEn,
      components: componentsEn,
      validationErrors: validationErrorsEn,
      layouts: layoutsEn,
    },
  },
  defaultNS: "legacy",
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
