import React, { useCallback, useMemo } from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import { colors } from "../../../../../theme/astra/colors";
import { useTranslation } from "react-i18next";
import { DEPARTMENT_INQUIRY_STATUS_CHIP_STYLES } from "../../../../../consts";
import { ReactComponent as WalletIcon } from "../../../../../assets/newDesignItems/wallet.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/newDesignItems/edit.svg";
import { useHistory } from "react-router-dom";
import { useSchoolInquiriesTableUrlTools } from "../../../hooks";
import { SchoolStatusChip } from "../../../../../components/SchoolStatusChip";
import { GetSchoolInquiriesForPrincipalResponseDto } from "../../../../../slices/schoolInquiries/types";
import { useDisplayPrice } from "../../../../../utils/useDisplayPrice";
import { useSelector } from "../../../../../store";
import { selectSelfSchoolCurrency } from "../../../../../slices/auth/selectors";
import { usePreActionCheck } from "../../../../../slices/schoolInquiries/hooks";
import { InquirySchoolStatus, RouteState } from "../../../../../types";
import { ItemsWithoutPriceTooltip } from "../../ItemsWithoutPriceTooltip";

type DepartmentProps = {
  inquiryId: number;
  departmentInquiry: GetSchoolInquiriesForPrincipalResponseDto["data"][number]["departmentInquiries"][number]; // Yeah
  inquiryBudget: number | null;
  inquirySchoolStatus: InquirySchoolStatus;
};

export const Department = ({
  departmentInquiry,
  inquiryId,
  inquiryBudget,
  inquirySchoolStatus,
}: DepartmentProps) => {
  const { t } = useTranslation();
  const history = useHistory<RouteState>();
  const { baseUrl } = useSchoolInquiriesTableUrlTools();
  const displayPrice = useDisplayPrice();

  const selfCurrency = useSelector(selectSelfSchoolCurrency);

  const goToDepartmentHandler = useCallback(() => {
    history.push(
      `${baseUrl}/${inquiryId}/details/${departmentInquiry.id}/items`,
      {
        justGoBack: true,
        scrollToTop: true,
      }
    );
  }, [baseUrl, departmentInquiry.id, history, inquiryId]);

  const editDepartmentHandler = useCallback(
    (event) => {
      event.stopPropagation();
      history.push(
        `${baseUrl}/${inquiryId}/department/${departmentInquiry.id}/editDepartment`,
        { justGoBack: true }
      );
    },
    [baseUrl, departmentInquiry.id, history, inquiryId]
  );

  const preActionCheck = usePreActionCheck();

  const disabledEdit = useMemo(() => {
    if (!inquiryBudget) {
      return true;
    }

    if (preActionCheck(inquirySchoolStatus)) {
      return true;
    }

    return (
      departmentInquiry.schoolStatus !== "new" &&
      departmentInquiry.schoolStatus !== "inProgress" &&
      departmentInquiry.schoolStatus !== "pendingApproval" &&
      departmentInquiry.schoolStatus !== "readyForSending" &&
      departmentInquiry.schoolStatus !== "approvedByHead" &&
      departmentInquiry.schoolStatus !== "needReviewByHead"
    );
  }, [
    departmentInquiry.schoolStatus,
    inquiryBudget,
    inquirySchoolStatus,
    preActionCheck,
  ]);

  const disabledDelete = useMemo(() => {
    if (preActionCheck(inquirySchoolStatus)) {
      return true;
    }

    const {
      schoolStatus,
      user: departmentInquiryUser,
      assignments,
    } = departmentInquiry;

    const hasTeacherInquiries = assignments?.some(
      ({ user }) => user.id !== departmentInquiryUser?.id
    );

    const hasItems = departmentInquiry && departmentInquiry.items.length > 0;

    if (schoolStatus === "cancelled" || hasTeacherInquiries || hasItems) {
      return true;
    }
  }, [departmentInquiry, inquirySchoolStatus, preActionCheck]);

  return (
    <Row>
      <DepartmentCard onClick={goToDepartmentHandler}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "40px",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "20px",
                color: colors.darkBlue,
                fontWeight: "500",
              }}
            >
              {departmentInquiry.department.name}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "baseline", gap: "5px" }}>
              <Typography
                sx={{
                  color: colors.dustBlue1,
                  fontSize: "13px",
                }}
              >
                {`${departmentInquiry.user?.firstName} ${departmentInquiry.user?.lastName}`}
              </Typography>
              <Typography sx={{ color: colors.dustBlue1, fontSize: "10px" }}>
                •{" "}
                {departmentInquiry.user?.title ??
                  t(`roles.${departmentInquiry.user?.role}`, { ns: "common" })}
              </Typography>
            </Box>
          </Box>
          <SchoolStatusChip
            chipStyle={
              DEPARTMENT_INQUIRY_STATUS_CHIP_STYLES[
                departmentInquiry.schoolStatus
              ]
            }
            label={t(
              `schoolDepartmentInquiryStatuses.${departmentInquiry.schoolStatus}`,
              {
                ns: "common",
              }
            )}
          />
        </Box>
        <ItemsWithoutPriceTooltip count={departmentInquiry.itemsWithoutPrice}>
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Box
              sx={{
                border: "1px solid #E0E8F1",
                borderRadius: "50px",
                width: "40px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "20px",
              }}
            >
              <WalletIcon />
            </Box>
            <DepartmentBudgetBox
              total={`${displayPrice(departmentInquiry?.totalAmount) || 0}${
                departmentInquiry.itemsWithoutPrice > 0 ? "+" : ""
              }`}
              currency={
                selfCurrency?.code?.toUpperCase() ||
                t("currencies.qar", { ns: "common" })
              }
              text={t("schoolInquiries.spentBudget", { ns: "pages" })}
            />
            <>
              <Divider
                orientation="vertical"
                sx={{ color: "red", height: "40px" }}
              />
              <DepartmentBudgetBox
                total={displayPrice(departmentInquiry.budget) || "N/A"}
                currency={
                  selfCurrency?.code?.toUpperCase() ||
                  t("currencies.qar", { ns: "common" })
                }
                text={t("schoolInquiries.assignedBudget", { ns: "pages" })}
              />
            </>
            {disabledDelete && disabledEdit ? (
              <Box sx={{ width: "60px" }} />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "20px",
                }}
              >
                <Button
                  onClick={editDepartmentHandler}
                  sx={{
                    minWidth: "40px",
                    minHeight: "40px",
                    height: "40px",
                    width: "40px",
                    borderRadius: "30px",
                    padding: 0,
                  }}
                >
                  <EditIcon color={"#5E7DA0"} />
                </Button>
              </Box>
            )}
          </Box>
        </ItemsWithoutPriceTooltip>
      </DepartmentCard>
    </Row>
  );
};

const DepartmentBudgetBox = ({
  total,
  currency,
  text,
}: {
  total: number | string;
  currency: string;
  text: string;
}) => (
  <Box>
    <Box sx={{ display: "flex", gap: "4px" }}>
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: "500",
          color: colors.darkBlue,
        }}
      >
        {total}
      </Typography>
      <Typography
        sx={{
          fonetSize: "12px",
          fontWeight: "400",
          color: colors.darkBlue,
        }}
      >
        {currency}
      </Typography>
    </Box>
    <Typography sx={{ fontSize: "10px", color: colors.dustBlue1 }}>
      {text}
    </Typography>
  </Box>
);

const DepartmentCard = styled(Card)({
  position: "relative",
  backgroundColor: colors.white,
  border: `1px solid ${colors.dustBlue6}`,
  padding: "32px",
  width: "100%",
  boxShadow: "0px 4px 10px 0px #1641730D",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  cursor: "pointer",
});

const Row = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "baseline",
  padding: "10px 16px",
});
