import React from "react";
import { GetShopProductsResponseDto } from "../../../../slices/shop/types";
import { Box as MuiBox, styled, Typography } from "@mui/material";
import { useDisplayPrice } from "../../../../utils/useDisplayPrice";

type PriceProps = Pick<
  GetShopProductsResponseDto["data"][number],
  "retailPrice" | "retailCurrency"
>;

export const Price = ({ retailPrice, retailCurrency }: PriceProps) => {
  const displayPrice = useDisplayPrice();

  if (!!retailPrice && !!retailCurrency) {
    return (
      <Box>
        <Typography
          component="span"
          sx={{
            color: "primary.dark",
            fontSize: "22px",
            lineHeight: "24px",
            fontWeight: "500",
          }}
        >
          {displayPrice(retailPrice)}
        </Typography>
        <Typography
          component="span"
          sx={{
            color: "primary.dark",
            fontSize: "14px",
            lineHeight: "33px",
            fontWeight: "500",
            marginLeft: "4px",
          }}
        >
          {retailCurrency?.code}
        </Typography>
      </Box>
    );
  }

  return <Box />;
};

const Box = styled(MuiBox)({
  marginTop: "auto",
});
